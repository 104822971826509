
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind utilities;
@tailwind components;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html {
  width: 100%;
  height: 100%;
}

body {
  background-color: theme('colors.body');
  color: theme('colors.white');
}

.overlay-slick-hero {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    theme('colors.body') 30%,
    transparent 90%,
    theme('colors.body')
  );
}

.overlay-film-cover {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    transparent 10%,
    theme('colors.body')
  );
}


.underline-custom {
  @apply relative inline-block; /* Tailwind utilities */
}

.underline-custom::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust this value to set space between text and underline */
  width: 100%;
  height: 2px; /* Thickness of the underline */
  background-color: gray ; /* Color of the underline */
}